import React, { useContext, useEffect, useState } from "react";
import { Lang } from "./langContext";

type Country = "nl" | "be" | "lu";

const initialCountryContextState = {
  country: "nl",
  setCountry: () => {
    throw new Error(
      "You forgot to wrap this in a CountryContextProvider object"
    );
  },
  countryReady: false,
  countryLanguages: ["en", "nl"],
  paramsCountry: "",
};

interface ContextShape {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  countryReady: boolean;
  countryLanguages: Array<Lang>;
  paramsCountry: string;
}

const CountryContext = React.createContext<ContextShape>(
  initialCountryContextState
);

export const CountryContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [country, setCountry] = useState(initialCountryContextState.country);
  const [countryReady, setCountryReady] = useState(
    initialCountryContextState.countryReady
  );
  const [countryLanguages, setCountryLanguages] = useState(
    initialCountryContextState.countryLanguages
  );
  const [paramsCountry, setParamsCountry] = useState("");

  useEffect(() => {
    if (typeof window === "undefined") return;
    const params = new URLSearchParams(window.location.search);
    const paramsCountry = params.get("country") as Country;
    setParamsCountry(paramsCountry);

    const { hostname } = window.location;
    if (hostname.endsWith(".be") || paramsCountry === "be") {
      setCountry("be");
      setCountryLanguages(["nl", "en", "fr"]);
    } else if (hostname.endsWith(".nl") || paramsCountry === "nl") {
      setCountry("nl");
      setCountryLanguages(["nl", "en"]);
    } else if (hostname.endsWith(".lu") || paramsCountry === "lu") {
      setCountry("lu");
      setCountryLanguages(["en", "fr"]);
    }

    setCountryReady(true);
  }, []);

  return (
    <CountryContext.Provider
      value={{
        country,
        setCountry,
        countryReady,
        countryLanguages,
        paramsCountry,
      }}
    >
      {countryReady && children}
    </CountryContext.Provider>
  );
};

export const useCountryContext = (): ContextShape => useContext(CountryContext);
