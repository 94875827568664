import React, { useContext, useState } from "react";

const initialIntroContextState = {
  introPlayed: false,
  setIntroPlayed: () => {
    throw new Error("You forgot to wrap this in a IntroContextProvider object");
  },
};

interface ContextShape {
  introPlayed: boolean;
  setIntroPlayed: React.Dispatch<React.SetStateAction<boolean>>;
}

const IntroContext = React.createContext<ContextShape>(
  initialIntroContextState
);

export const IntroContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [introPlayed, setIntroPlayed] = useState(
    initialIntroContextState.introPlayed
  );

  return (
    <IntroContext.Provider
      value={{
        introPlayed,
        setIntroPlayed,
      }}
    >
      {children}
    </IntroContext.Provider>
  );
};

export const useIntroContext = (): ContextShape => useContext(IntroContext);
