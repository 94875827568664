import React from "react";

import { LangContextProvider } from "./src/context/langContext";
import { FormContextProvider } from "./src/context/formContext";
import { ErrorsContextProvider } from "./src/context/errorsContext";
import { CitiesContextProvider } from "./src/context/citiesContext";
import { GeolocationContextProvider } from "./src/context/geolocationContext";
import { IntroContextProvider } from "./src/context/introContext";
import { CountryContextProvider } from "./src/context/countryContext";

export const wrapRootElement = ({ element }) => (
  <GeolocationContextProvider>
    <CountryContextProvider>
      <LangContextProvider>
        <FormContextProvider>
          <ErrorsContextProvider>
            <IntroContextProvider>
              <CitiesContextProvider>{element}</CitiesContextProvider>
            </IntroContextProvider>
          </ErrorsContextProvider>
        </FormContextProvider>
      </LangContextProvider>
    </CountryContextProvider>
  </GeolocationContextProvider>
);
