import React, { useContext, useState } from "react";

const initialCitiesContextState = {
  cities: {
    nyc: false,
    cur: false,
    lis: false,
  },
  setCities: () => {
    throw new Error(
      "You forgot to wrap this in a CitiesContextProvider object"
    );
  },
};

interface ContextShape {
  cities: number;
  setCities: React.Dispatch<React.SetStateAction<number>>;
}

const CitiesContext = React.createContext<ContextShape>(
  initialCitiesContextState
);

export const CitiesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [cities, setCities] = useState(initialCitiesContextState.cities);

  return (
    <CitiesContext.Provider
      value={{
        cities,
        setCities,
      }}
    >
      {children}
    </CitiesContext.Provider>
  );
};

export const useCitiesContext = (): ContextShape => useContext(CitiesContext);
