import React, { useContext, useEffect, useState } from "react";

const initialGeolocationContextState = {
  geolocation: "",
  setGeolocation: () => {
    throw new Error(
      "You forgot to wrap this in a GeolocationContextProvider object"
    );
  },
  geolocationReady: false,
};

interface ContextShape {
  geolocation: string;
  setGeolocation: React.Dispatch<React.SetStateAction<string>>;
  geolocationReady: boolean;
}

const GeolocationContext = React.createContext<ContextShape>(
  initialGeolocationContextState
);

export const GeolocationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [geolocation, setGeolocation] = useState(
    initialGeolocationContextState.geolocation
  );
  const [geolocationReady, setGeolocationReady] = useState(
    initialGeolocationContextState.geolocationReady
  );

  useEffect(() => {
    fetch("https://api.country.is/")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error, status = ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setGeolocation(json.country);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setGeolocationReady(true));
  }, []);

  return (
    <GeolocationContext.Provider
      value={{
        geolocation,
        setGeolocation,
        geolocationReady,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocationContext = (): ContextShape =>
  useContext(GeolocationContext);
