exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activities-tsx": () => import("./../../../src/pages/activities.tsx" /* webpackChunkName: "component---src-pages-activities-tsx" */),
  "component---src-pages-destinations-tsx": () => import("./../../../src/pages/destinations.tsx" /* webpackChunkName: "component---src-pages-destinations-tsx" */),
  "component---src-pages-final-tsx": () => import("./../../../src/pages/final.tsx" /* webpackChunkName: "component---src-pages-final-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-personal-details-tsx": () => import("./../../../src/pages/personal-details.tsx" /* webpackChunkName: "component---src-pages-personal-details-tsx" */),
  "component---src-pages-play-tsx": () => import("./../../../src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */),
  "component---src-pages-ready-tsx": () => import("./../../../src/pages/ready.tsx" /* webpackChunkName: "component---src-pages-ready-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

